<template>
  <div style="background-color: #f6f6f6">
    <wd-navbar>
      <div slot="left" class="middle" @click="$goHome">
        <wd-icon name="arrow-left" />
      </div>
      <div>
        <span>费用配置</span>
      </div>
    </wd-navbar>
    <wd-card title="充值金额设置" type="rectangle">
      <div>
        <wd-input type="number" required label="水电费最少充值金额" label-width="50%" :no-border="true" v-model="form.miniRechargeFee" placeholder="请输入最少支付金额">
          <span slot="suffix" style="display: flex; height: 100%; align-items: center">
            元
          </span>
        </wd-input>
        <wd-divider style="margin-top: 15px; margin-bottom: 15px">水电缴费便捷金额设置</wd-divider>
        <wd-input type="number" required label="一档" :no-border="true" v-model="form.oneRechargeFee" placeholder="请输入一档金额">
          <span slot="suffix" style="display: flex; height: 100%; align-items: center">
            元
          </span>
        </wd-input>
        <wd-input type="number" required label="二档" :no-border="true" v-model="form.twoRechargeFee" placeholder="请输入二档金额">
          <span slot="suffix" style="display: flex; height: 100%; align-items: center">
            元
          </span>
        </wd-input>
        <wd-input type="number" required label="三档" :no-border="true" v-model="form.threeRechargeFee" placeholder="请输入三档金额">
          <span slot="suffix" style="display: flex; height: 100%; align-items: center">
            元
          </span>
        </wd-input>
      </div>
      <wd-button slot="footer" size="small" type="info" @click="submit">
        <div class="bottom">
          <img src="../../assets/img/setting.svg" style="max-width: 16px; margin-right: 5px" alt="">确定
        </div>
      </wd-button>
    </wd-card>
  </div>
</template>

<script>
  export default {
    data(){
        return {
            form: {
                miniRechargeFee: null,
                oneRechargeFee: null,
                twoRechargeFee: null,
                threeRechargeFee: null,
                roleId: null
            },
            roleKey: null,

        }
    },
    created() {
        this.roleKey = localStorage.getItem('roleKey')
        this.form.roleId = this.roleKey
        this.getInfo()
    },
    methods: {
        getInfo(){
            this.$http.get("/external/appFeeConfig/list", { params: { roleId: this.roleKey } }).then(res=>{
                if( res.rows && res.rows.length > 0 ) {
                    this.form = res.rows[0]
                }
            })
        },
        submit(){
            const { miniRechargeFee, oneRechargeFee, twoRechargeFee, threeRechargeFee } = this.form

            if (!miniRechargeFee) {
                this.miniRechargeFeeError = true
                this.$toast.error('请填写最少充值金额')
                return
            }

            if (!oneRechargeFee) {
                this.oneRechargeFeeError = true
                this.$toast.error('请填写第一档金额')
                return
            }

            if (!twoRechargeFee) {
                this.twoRechargeFeeError = true
                this.$toast.error('请填写第二档金额')
                return
            }

            if (!threeRechargeFee) {
                this.threeRechargeFeeError = true
                this.$toast.error('请填写第三档金额')
                return
            }
            this.$http.post("/external/appFeeConfig", this.form).then(res=>{
                if( res.code == 200 ) {
                    this.$toast.success("配置成功!")
                    this.getInfo()
                } else {
                    this.$toast.error(res.msg)
                }
            })
        }
    }
  }
</script>

<style lang="less" scoped>
.bottom{
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
